import React from 'react';
import Banner from '../../ui-components/Banner';

export const BannerSection = () => {
	const components1 = [
		{
			component: (
				<p className='text-white font-bold font-superclarendon md:text-[44px] sm:text-[38px] text-[32px] whitespace-nowrap'>
					Mint Inscriptions
				</p>
			)
		},
		{
			component: (
				<p className='font-bold font-superclarendon md:text-[44px] sm:text-[38px] text-[32px] text-primary whitespace-nowrap'>
					List NFTs!
				</p>
			)
		}
	];
	const components2 = [
		{
			component: (
				<p className='text-white font-bold font-superclarendon md:text-[44px] sm:text-[38px] text-[32px] whitespace-nowrap'>
					Buy NFTs
				</p>
			)
		},
		{
			component: (
				<a
					href='https://app.blobscriptions.xyz/'
					target='_blank'
					className='font-bold font-superclarendon md:text-[44px] sm:text-[38px] text-[32px] text-white whitespace-nowrap border border-solid border-[#E8E8E8] rounded-[33px] px-6 py-1'
					rel='noreferrer'
				>
					Explore Marketplace
				</a>
			)
		}
	];

	const components3 = [
		{
			component: (
				<p className='text-white font-bold font-superclarendon md:text-[44px] sm:text-[38px] text-[32px] whitespace-nowrap'>
					Now Live on
				</p>
			)
		},
		{
			component: (
				<div className='md:h-[45px] md:w-[150px] h-[40px] w-[135px]'>
					<img
						src='avail-logo.png'
						alt='avail'
					/>
				</div>
			)
		},
		{
			component: (
				<div className='md:h-[45px] md:w-[150px] h-[40px] w-[135px]'>
					<img
						src='taiko-logo.png'
						alt='taiko'
					/>
				</div>
			)
		},
		{
			component: (
				<div className='w-[200px]'>
					<img
						src='eclipse-logo-new.png'
						alt='eclipse'
					/>
				</div>
			)
		}
	];

	return (
		<div className='p-4 w-full mt-4'>
			<div className='border-[0.6px] border-solid border-[#E8E8E8]'>
				<div className='md:py-4 py-2'>
					<Banner components={components1} />
				</div>
				<div className='md:py-4 py-2 border-y-[0.6px] border-solid border-[#E8E8E8]'>
					<Banner
						direction='right'
						components={components2}
					/>
				</div>
				<div className='md:py-4 py-2'>
					<Banner components={components3} />
				</div>
			</div>
		</div>
	);
};
