import React from 'react';

const OnlyfunBanner = () => {
	return (
		<div className='bg-transparent relative'>
			<img
				className='absolute z-20 top-0 left-0 h-full w-full'
				src='banner-bg.png'
				alt='background'
			/>
			<div className='opacity px-2 py-4 bg-gradient-to-r w-full from-[#123F5F]/[0.9] via-[#143044]/[0.9] to-[#6C0C7F]/[0.5] flex items-center sm:flex-row flex-col justify-center gap-x-2 font-urbanist font-semibold text-base lg:text-xl relative z-30'>
				<span className='flex items-center min-[400px]:flex-row flex-col gap-x-1'>
					<span className='flex items-center gap-x-1'>
						<img
							src='rocket-icon.png'
							alt='only.fun'
							className='h-[32px] w-[32px]'
						/>
						Blobscription has integrated with
					</span>
					<span className='flex items-center gap-x-1'>
						<img
							src='pill-icon.png'
							alt='only.fun'
							className='h-[26px] w-[14px] mx-1'
						/>{' '}
						onlydotfun✨
					</span>
				</span>
				<span className='flex items-center gap-x-1'>
					Campaign Live,{' '}
					<a
						className='text-[#80D4FF]'
						href='https://app.blobscriptions.xyz/'
						target='_blank'
						rel='noreferrer'
					>
						Participate now !!
					</a>
				</span>
			</div>
		</div>
	);
};

export default OnlyfunBanner;
